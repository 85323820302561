import * as React from 'react'
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Typography, Paper, TextField } from '@mui/material/'

const ItemAttachmentRow = React.memo(({ index, row, handleChange }) => {
    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" sx={{ width: '20%' }}>
                <TextField
                    variant="standard"
                    autoComplete="off"
                    value={row.item_attachment || ''}
                    onChange={(e) => handleChange(index, 'item_attachment', e)}
                />
            </TableCell>
            <TableCell align="left">
                <TextField
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    value={row.description || ''}
                    onChange={(e) => handleChange(index, 'description', e)}
                />
            </TableCell>
        </TableRow>
    )
}, (prevProps, nextProps) => {
    return prevProps.row.item_attachment === nextProps.row.item_attachment &&
        prevProps.row.description === nextProps.row.description &&
        prevProps.index === nextProps.index
})

const ItemAttachmentsTable = React.memo(({ itemIndex, purchaseOrderItems, setPurchaseOrderItems }) => {
    const handleChange = (rowIndex, fieldName, e) => {
        
        setPurchaseOrderItems(prevItems => {
            const updatedItems = [...prevItems]
            const updatedRow = {...updatedItems[itemIndex]['item_attachments_array'][rowIndex]}
            updatedRow[fieldName] = e.target.value
            updatedItems[itemIndex]['item_attachments_array'][rowIndex] = updatedRow
            return updatedItems
        })
    }

    return (
        <div>
            <Typography variant='h6'>Item Attachment(s)</Typography>
            <TableContainer component={Paper} sx={{ height: '300px' }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item Attachment</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrderItems[itemIndex]['item_attachments_array'].map((row, index) => (
                            <ItemAttachmentRow key={index} index={index} row={row} handleChange={handleChange} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
})

export default ItemAttachmentsTable