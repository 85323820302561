import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

// Color function to dynamically change border color based on dropzone state
const getColor = (props) => {
    if (props.$error) {
        return '#ff1744' // Red for error (typeError is true)
    }
    if (props.$isDragAccept) {
        return '#00e676' // Green for accepted files
    }
    if (props.$isDragReject) {
        return '#ff1744' // Red for rejected files
    }
    if (props.$isFocused) {
        return '#2196f3' // Blue when focused
    }
  return 'grey' // Default border color
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: grey;
    outline: none;
    transition: border .24s ease-in-out;
    &:hover {
        border-color: #2196f3;  // Blue color on hover
    }
`

function StyledDropzone({ purchaseOrderState, setPurchaseOrderState, initState, fileTypes, dropZoneText }) {
    
    // useDropzone hook with options to accept only PDF files
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
        } = useDropzone({
            maxFiles: 1
    })

    const handleFile = (acceptedFiles) => {
        const selectedFile = acceptedFiles[0]
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            setPurchaseOrderState(prev => ({...prev, fileName: selectedFile.name}))
            setPurchaseOrderState(prev => ({...prev, size: selectedFile.size}))
            setPurchaseOrderState(prev => ({...prev, typeError: false}))
            const formData = new FormData()
            formData.append('file', selectedFile)
            setPurchaseOrderState(prev => ({...prev, pdf: formData}))
            // setPurchaseOrderState(prev => ({...prev, convertedExcelFile: null}))
        } else {
            alert("Invalid file type. Please select a valid file type.")
            setPurchaseOrderState(initState)
            setPurchaseOrderState(prev => ({...prev, typeError: true}))
        }
    }

    // Effect hook to log and update state when files are accepted
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            handleFile(acceptedFiles)
        }
        // eslint-disable-next-line
    }, [acceptedFiles])  // Runs when acceptedFiles changes

    return (
            <Container {...getRootProps()} $isFocused={isFocused} $isDragAccept={isDragAccept} $isDragReject={isDragReject} $error={purchaseOrderState.typeError}>
                <input {...getInputProps()} />
                <p style={{ padding: 10 }}>{dropZoneText}</p>
                <div style={{height: '20px'}}>
                    {purchaseOrderState.pdf ? (
                        <p><strong>{purchaseOrderState.fileName}</strong> ({purchaseOrderState.size} bytes)</p>
                    ):(<p>No PDF Selected</p>)}
                </div>
                <div style={{ display: 'flex', alignSelf: 'end' }}>
                    <DeleteOutlineIcon 
                        sx={{ padding: 0, '&:hover': { color: 'red'}, transition: 'color .15s ease-in-out' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setPurchaseOrderState(initState)
                        }}
                    />
                </div>
            </Container>
    )
}

export default StyledDropzone
