import * as React from 'react'
import { Grid, Fade, Box, Typography, TextField, Button } from '@mui/material/'
import GetRoute from '../../../GetRoute'
import LoadingButton from '@mui/lab/LoadingButton'
import DoneIcon from '@mui/icons-material/Done'

export default function NoContactFound({ client, buyerInfo, purchaseOrderInformation, handleChange, previousStep, nextStep }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    const [addingContact, setAddingContact] = React.useState(false)
    const [addContactResults, setAddContactResults] = React.useState(false)
    
    const addContact = async () => {
        setAddingContact(true)
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/addContact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'  // Added header to prevent caching
                },
                body: JSON.stringify({
                    buyerInfo: buyerInfo,
                    company: purchaseOrderInformation.company_name,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            setAddContactResults(data.result)
            setAddingContact(false)
        } catch (error) {
            setAddingContact(false)
            alert(error.message)
            // setPurchaseOrderState(prev => ({...prev, typeError: true, linearProgressToggle: false }))
        }
    }
    
    return (
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', padding: 1, height: '400px' }}>
            <Fade
                in={true}
                unmountOnExit
            >
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h6'>
                        No contact was found with the buyer name: <span className="purchaseContactImportance">{buyerInfo.buyer_name}</span>.
                    </Typography>
                    <Grid container spacing={1} paddingLeft={2} paddingBottom={1}>
                        <Grid item xs={12} paddingBottom={2}>
                            <Typography variant='body1' paddingBottom={.5}>
                                Please review the information and decide if you would like to add this contact to the database.
                            </Typography>
                            <Typography variant='body1'>
                                If the name is incorrect, return the Verify Information step and correct the Buyer Name inside Buyer Information, then return to Check Contact Information.
                            </Typography>
                        </Grid>
                        <Grid item xs={5} padding={1}>
                                <TextField
                                    sx={{ width: '80%' }}
                                    label="Purchase Contract Phone Number"
                                    value={buyerInfo.phone_number}
                                    autoComplete='off'
                                    name="phone_number"
                                    onChange={handleChange}
                                />
                        </Grid>
                        <Grid item xs={5} padding={1}>
                                <TextField
                                    sx={{ width: '80%' }}
                                    label="Purchase Contract Email"
                                    value={buyerInfo.email}
                                    autoComplete='off'
                                    name="email"
                                    onChange={handleChange}
                                />
                        </Grid>
                        <Grid item xs={5} padding={1}>
                                <TextField
                                    sx={{ width: '80%' }}
                                    label="Purchase Contract Fax"
                                    value={buyerInfo.fax}
                                    autoComplete='off'
                                    name="fax"
                                    onChange={handleChange}
                                />
                        </Grid>
                        <Grid item xs={5} padding={1}>
                                <TextField
                                    sx={{ width: '80%' }}
                                    label="Purchase Contract Customer"
                                    value={purchaseOrderInformation.company_name}
                                    autoComplete='off'
                                    name="company_name"
                                    onChange={handleChange}
                                />
                        </Grid>
                    </Grid>
                    <Grid container paddingTop={2}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>  
                            <Typography variant='h6'>
                                Would you like to add the contact information?
                            </Typography>
                        </Grid>
                        <Grid item xs={2} paddingLeft={2}>
                            <LoadingButton
                                loading={addingContact}
                                id="addContact"
                                disabled={addContactResults}
                                endIcon={!addContactResults ? (null) : (<DoneIcon sx={{ color: 'grey' }}/>)}
                                sx={{
                                    padding: 1.2,
                                    color: 'white', 
                                    backgroundColor: '#0e4780',
                                    width: '100%',
                                    minWidth: '123px',
                                    textTransform: 'none',
                                    '&:hover': { cursor: 'pointer', backgroundColor: '#003c6c' }, '& .MuiCircularProgress-root': { color: 'white' } 
                                }}
                                onClick={ async () => {
                                    const button = document.querySelector("#addContact")
                                    button.disabled = true
                                    addContact()
                                    setTimeout(() => button.disabled = false, 3000)
                                    // await new Promise(r => setTimeout(r, 1000))
                                }}
                            >
                                {!addContactResults ? (<p>Add Contact </p>) : (<Typography color='grey'>Added</Typography>)}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'end', paddingTop: 3 }}>
                        <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                            <Button
                                variant="contained"
                                id="submitButton"
                                size='large'
                                // disabled={purchaseOrderState.pdf == null}
                                sx={{ 
                                    backgroundColor: '#5d6c7c',
                                    width: '100%',
                                    minWidth: '123px',
                                    color: 'white',
                                    textTransform: 'none',
                                    "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                }}
                                onClick={() => {
                                    const button = document.querySelector('#submitButton')
                                    button.disabled = true
                                    previousStep()
                                    setTimeout(() => button.disabled = false, 2000)
                                }}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                            <Button
                                variant="contained"
                                id="submitButton"
                                size='large'
                                // disabled={purchaseOrderState.pdf == null}
                                sx={{
                                    backgroundColor: '#0e4780',
                                    color: 'white',
                                    width: '100%',
                                    minWidth: '123px',
                                    textTransform: 'none',
                                    "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                }}
                                onClick={() => {
                                    const button = document.querySelector('#submitButton')
                                    button.disabled = true
                                    nextStep()
                                    setTimeout(() => button.disabled = false, 2000)
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Grid>
    )
}