import React, { useState } from 'react'
import { Typography, IconButton, Box, Paper, Grid, FormHelperText, Button, Popover } from '@mui/material/'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import AssessmentIcon from '@mui/icons-material/Assessment'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'
import GetRoute from '../../GetRoute'
import parseReportData from '../../MachineReports/ParseData/parseReportData'
import { getMachineList, generateReportDataOptions } from '../../MachineReports/utils'
import MachineReportDialog from '../../MachineReports/MachineReportDialog'

const MachineReportPopover = ({ name, client }) => {
    const initialState = {
        reportType: `fetchMachines`,
        reportFetchResult: [],
        itemsToGet: [name],
        startDate: dayjs(),
        endDate: dayjs(new Date()),
        reportErrorValue: false,
        itemsToGetErrorValue: false,
        linearProgressToggle: false,
        programTableToggle: false,
        errorTableToggle: false,
        dailyRecordsToggle: false,
        dailyRecordsBarGraph: false,
        dailyRecordHLATable: false,
        dailyRecordHLABarGraph: false,
        AverageOperatingTimePieChart: false,
        groupOverview: true,
        excludeWeekend: false,
        reportDialogToggle: false

    }

    const reportValuesInitState = {
        ScopeObject: {
            fetchDepartments: "Department Report",
            fetchMachines: "Machine Report",
            fetchBuildings: "Building Report",
            allMachines: "Full Machine Report"
        },
        generatedReportOptions: null,
        machineNameString: "",
    }
    const [generateFormValues, setGenerateFormValues] = useState(initialState)
    const [report, setReport] = useState(reportValuesInitState)
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const fetchReport = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/fetchReport`, {
                reportType: generateFormValues.reportType,
                itemsToGet: generateFormValues.itemsToGet,
                excludeWeekend: generateFormValues.excludeWeekend,
                startDate: generateFormValues.startDate['$d'],
                endDate: generateFormValues.endDate['$d'],
                token: client.token
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store, must-revalidate', // Forces validation with the server, but can use cached data
                }
            })
            const parsedData = parseReportData(response.data, generateFormValues)
            const genReportOptions = generateReportDataOptions(parsedData, generateFormValues)
            const machineNames = getMachineList(parsedData.machines)
            setReport(prev => ({...prev, generatedReportOptions: genReportOptions, machineNameString: machineNames}))
            setGenerateFormValues(prev => ({...prev, reportDialogToggle: true}))
        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <PopupState variant="popover">
                {(popupState) => (
                    <div>
                        <IconButton id='MachineReportButton' {...bindTrigger(popupState)}>
                            <AssessmentIcon/>
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box sx={{ padding: 1, paddingBottom: 2 }}>
                                <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                    <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color: 'white', paddingLeft: 2, paddingRight: 2 }}>Generate Overview Report</Typography>
                                </Paper>
                                <FormHelperText>This will generate a form for {name}.</FormHelperText>
                            </Box>
                            <Grid container
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <DatePicker 
                                        label="Start Date" 
                                        timezone="America/Chicago"
                                        value={generateFormValues.startDate}
                                        onChange={(e) => {
                                            setGenerateFormValues((prev) => ({...prev, startDate: e}))
                                        }}
                                        maxDate={dayjs()}
                                    />
                                    <FormHelperText sx={{ paddingLeft: 2, paddingBottom: 2 }}>Start date is selected at 12:00AM</FormHelperText>
                                </Grid>
                                <Grid item sx={{ width: '100%', marginTop: 'auto' }}>
                                    <Grid container justifyContent="center">
                                        <Grid item sx={{ width: '50%', padding: 1, alignContent: 'end' }}>
                                            <Button
                                                variant="contained"
                                                id="cancelButton"
                                                size='large'
                                                sx={{
                                                    backgroundColor: '#5d6c7c',
                                                    color: 'white',
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                                }}
                                                onClick={() => {
                                                    const button = document.querySelector('#cancelButton')
                                                    popupState.close()
                                                    setTimeout(() => button.disabled = false, 2000)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{ width: '50%', padding: 1, paddingLeft: 0, alignContent: 'end' }}>
                                            <Button
                                                variant="contained"
                                                id="submitButton"
                                                size='large'
                                                sx={{
                                                    backgroundColor: '#0e4780',
                                                    color: 'white',
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                                }}
                                                onClick={async () => {
                                                    const button = document.querySelector('#submitButton')
                                                    button.disabled = true
                                                    await fetchReport()
                                                    setTimeout(() => button.disabled = false, 2000)
                                                    popupState.close()
                                                    
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Popover>
                    </div>
                )}
            </PopupState>
            <MachineReportDialog 
                initialState={initialState}
                generateFormValues={generateFormValues}
                setGenerateFormValues={setGenerateFormValues}
                report={report}
            />
        </>
    )
}


export default MachineReportPopover