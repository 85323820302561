import * as React from 'react'
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Typography, Paper, TextField } from '@mui/material/'

const ItemScheduleRow = React.memo(({ row, index, handleChange }) => {
    return (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" sx={{ width: '25%'}}>
                <TextField
                    variant="standard"
                    autoComplete="off"
                    value={row.quantity_scheduled || ''}
                    onChange={(e) => handleChange(index, 'quantity_scheduled', e)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    value={row.contractual_on_dock_schedule || ''}
                    onChange={(e) => handleChange(index, 'contractual_on_dock_schedule', e)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    value={row.required_date || ''}
                    onChange={(e) => handleChange(index, 'required_date', e)}
                />
            </TableCell>
        </TableRow>
    )
})

const ItemScheduleTable = React.memo(({ itemIndex, purchaseOrderItems, setPurchaseOrderItems }) => {
    const handleChange = (rowIndex, fieldName, e) => {
            setPurchaseOrderItems(prevItems => {
                prevItems[itemIndex]['item_schedule_array'][rowIndex][fieldName] = e.target.value;
                return [...prevItems]
            })
        }
    
    return (
        <div>
            <Typography variant='h6'>Item Schedule</Typography>
            <TableContainer component={Paper} sx={{ height: '240px'}}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Quantity Scheduled</TableCell>
                            <TableCell>Contractual On Dock Schedule</TableCell>
                            <TableCell>Required Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrderItems[itemIndex]['item_schedule_array'].map((row, index) => (
                            <ItemScheduleRow key={index} index={index} row={row} handleChange={handleChange} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
})

export default ItemScheduleTable