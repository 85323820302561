import * as React from 'react'
import { Typography, Grid, TextField, InputAdornment } from '@mui/material/'

const PurchaseContractBody = React.memo(({ purchaseOrderInformation, setPurchaseOrderInformation }) => {
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setPurchaseOrderInformation((prev) => ({...prev, [name]: value}))
    }

    return (
        <Grid container sx={{ paddingTop: 1 }}>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Supplier No"
                    name="supplier_no"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.supplier_no || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="BEST Code"
                    name="best_code"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.best_code || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    fullWidth
                    multiline
                    row={5}
                    label="Supplier Address"
                    name="supplier_address"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.supplier_address || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={3} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="NAICS"
                    name="naics"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.naics || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={3} sx={{ padding: .5, display: 'flex', justifyContent: 'end' }}>
                <TextField
                    label="Size Std Emp"
                    name="size_std_emp"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.size_std_emp || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    fullWidth
                    multiline
                    row={5}
                    label="Manufacturer Address"
                    name="manufacturer_address"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.manufacturer_address || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6}/>
            <Grid item xs={12} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Confirm To"
                    name="confirm_to"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.confirm_to || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Payment Type"
                    name="payment_type"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.payment_type || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'end' }}>
                <TextField
                    label="Payment Rate"
                    name="payment_rate"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.payment_rate || ''}
                    onChange={handleChange}
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Liquidation Type"
                    name="liquidation_type"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.liquidation_type || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'end' }}>
                <TextField
                    label="Liquidation Rate"
                    name="liquidation_rate"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.liquidation_rate || ''}
                    onChange={handleChange}
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'Start' }}>
                <TextField
                    fullWidth
                    multiline
                    label="Ship To"
                    name="ship_to"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.ship_to || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={5} sx={{ padding: .5, display: 'flex', justifyContent: 'end', alignSelf: 'flex-end' }}>
                <TextField
                    fullWidth
                    label="Routing"
                    name="routing"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.routing || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={7}/>
            <Grid item xs={5} sx={{ padding: .5, display: 'flex', justifyContent: 'Start' }}>
                <TextField
                    label="FOB"
                    name="fob"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.fob || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={7}/>
            <Grid item xs={5} sx={{ padding: .5, display: 'flex', justifyContent: 'Start' }}>
                <TextField
                    label="Shipping Payment Method"
                    name="shipping_payment_method"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.shipping_payment_method || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>Purchase Contract Revision Notes</Typography>
                <TextField
                    multiline
                    fullWidth
                    name="purchase_contract_revision_notes"
                    variant="outlined"
                    autoComplete="off"
                    value={purchaseOrderInformation.purchase_contract_revision_notes || ''}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )   
})

export default PurchaseContractBody