import * as React from 'react'
import { useState, useEffect } from "react"
import { Typography, Grid, CircularProgress, Fade, Box } from '@mui/material/'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import GetRoute from '../../../GetRoute'
import SkeletonComponent from './SkeletonComponent'
import ContactFoundUpdateData from './ContactFoundUpdateData'
import NoContactFound from './NoContactFound'
import ContactFoundCorrectData from './ContactFoundCorrectData'
import MultipleContactsFound from './MultipleContactsFound'

export default function CheckContactInformation({ client, purchaseOrderInformation, setPurchaseOrderInformation, purchaseOrderItems, buyerInfo, setBuyerInfo, purchaseOrderAttachments, previousStep, nextStep }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    
    const initSearchState = {
        checkCustomerContactProgress: true,
        customerContactPassed: false,
        customerContactFailed: false,
        customerContactNotFound: false,
        customerContactFound: false,
        customerContactResult: null,
        multipleCustomersFound: false
    }
    const [searchState, setSearchState] = useState(initSearchState)

    const initDatabaseContactInfo = {
        buyer_name: "",
        email: "",
        fax: "",
        loc_bldg_ms: "",
        phone_number: "",
        customer: "",
        contactInfoRecieved: false
    }
    const [databaseContactInfo, setDatabaseContactInfo] = useState(initDatabaseContactInfo)
    
    const handleChange = (event) => {
        const { name, value } = event.target || event
        if(name === 'company_name') {
            setPurchaseOrderInformation((prev) => ({...prev, [name]: value}))
        } else {
            setBuyerInfo((prev) => ({...prev, [name]: value}))
        }
    }

    const checkCustomerContact = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/checkCustomerContact`, {
                method: 'POST',
                headers: {
                    'Cache-Control': 'no-store',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    buyer_info: buyerInfo,
                    customer: purchaseOrderInformation.company_name,
                    token: client.token
                })
            })
            await new Promise(r => setTimeout(r, 1500))
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            } else {
                const databaseResponse = await response.json()
                let contactData = databaseResponse.customer_data
                const contactDataLength = contactData.length

                setSearchState((prev) => ({...prev, checkCustomerContactProgress: false }))
                // If no customer with that name was found
                if (contactDataLength === 0) {
                    await new Promise(r => setTimeout(r, 500))
                    setSearchState((prev) => ({...prev, customerContactFailed: true, customerContactNotFound: true}))
                
                // If a customer was found but the contact information does not match
                } else if (contactDataLength === 1 && !databaseResponse.data_match) {
                    contactData = contactData[0]
                    await new Promise(r => setTimeout(r, 500))
                    setSearchState((prev) => ({...prev, customerContactFailed: true, customerContactFound: true}))
                    setDatabaseContactInfo((prev) => ({...prev, buyer_name: contactData.Contact_Name, email: contactData.Email_Address, phone_number: contactData.Phone, customer: contactData.Customer, fax: contactData.Fax , contactInfoRecieved: true}))
                
                // If a customer was found and the contact information matches
                } else if (contactDataLength === 1 && databaseResponse.data_match) {
                    contactData = contactData[0]
                    await new Promise(r => setTimeout(r, 500))
                    setSearchState((prev) => ({...prev, customerContactPassed: true, customerContactFound: true}))
                    setDatabaseContactInfo((prev) => ({...prev, buyer_name: contactData.Contact_Name, email: contactData.Email_Address, phone_number: contactData.Phone, customer: contactData.Customer, fax: contactData.Fax , contactInfoRecieved: true}))
                
                } else if (contactDataLength > 1) {
                    await new Promise(r => setTimeout(r, 500))
                    setSearchState((prev) => ({...prev, customerContactFailed: true, multipleCustomersFound: true}))
                }
            }
        } catch (error) {
            setSearchState((prev) => ({...prev, checkCustomerContactProgress: false}))
            alert(error.message)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        checkCustomerContact()
        // eslint-disable-next-line
    }, [])
    
    return (
        <Grid container sx={{ display: 'flex', justifyContent: 'center'}}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                    Checking contact information
                </Typography>
                <Box width={40} height={40} display="flex" alignItems="center">
                    <Fade in={searchState.checkCustomerContactProgress} unmountOnExit>
                        <CircularProgress size={20} />
                    </Fade>
                    <Fade in={searchState.customerContactPassed} unmountOnExit>
                        <CheckIcon color='success'/>
                    </Fade>
                    <Fade in={searchState.customerContactFailed} unmountOnExit>
                        <ErrorOutlineIcon color='error'/>
                    </Fade>
                </Box>
            </Grid>
            {searchState.checkCustomerContactProgress ? (
                <SkeletonComponent />
            ) : (null)}
            {!searchState.checkCustomerContactProgress && searchState.customerContactFailed && searchState.customerContactFound ? (
                <ContactFoundUpdateData
                    client={client}
                    buyerInfo={buyerInfo}
                    databaseContactInfo={databaseContactInfo}
                    purchaseOrderInformation={purchaseOrderInformation}
                    handleChange={handleChange}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ):(null)}
            {!searchState.checkCustomerContactProgress && searchState.customerContactFailed && searchState.customerContactNotFound ? (
                <NoContactFound
                    client={client}
                    buyerInfo={buyerInfo}
                    purchaseOrderInformation={purchaseOrderInformation}
                    handleChange={handleChange}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)}
            {!searchState.checkCustomerContactProgress && searchState.customerContactPassed && searchState.customerContactFound ? (
                <ContactFoundCorrectData
                    buyerInfo={buyerInfo}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)}
            {!searchState.checkCustomerContactProgress && searchState.customerContactFailed && searchState.multipleCustomersFound ? (
                <MultipleContactsFound
                    buyerInfo={buyerInfo}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)}
        </Grid>
    )
}