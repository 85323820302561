import * as React from 'react'
import { Grid, Fade, Box, Typography, TextField, Button } from '@mui/material/'
import GetRoute from '../../../GetRoute'
import LoadingButton from '@mui/lab/LoadingButton'
import DoneIcon from '@mui/icons-material/Done'

export default function SalesOrderFoundCorrectData({ client, buyerInfo, purchaseOrderInformation, handleChange, previousStep, nextStep }) {

    return (
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', padding: 1, height: '400px' }}>
            <Fade
                in={true}
                unmountOnExit
            >
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h6'>
                        A Sales Order Found with a Customer PO:  {purchaseOrderInformation.purchase_contract_no}.
                    </Typography>
                    <Typography variant='h6'>
                        The data matches! Nothing else to do here you may continue.
                    </Typography>
                    <Grid container>
                        <Grid item>
                        </Grid>
                        <Grid item>
                        </Grid>
                        <Grid item>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Grid>
    )
}

