import * as React from 'react'
import { useState, useEffect } from "react"
import { Typography, Grid, CircularProgress, Fade, Box, Button } from '@mui/material/'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import GetRoute from '../../../GetRoute'
import SkeletonComponent from '../CheckContactInformation/SkeletonComponent'
import SalesOrderNotFound from './SalesOrderNotFound'
import SalesOrderFoundCorrectData from './SalesOrderFoundCorrectData'
import SalesOrderFoundUpdateData from './SalesOrderFoundUpdateData'

export default function CheckSalesOrderInformation({ client, purchaseOrderInformation, setPurchaseOrderInformation, purchaseOrderItems, buyerInfo, setBuyerInfo, purchaseOrderAttachments, previousStep, nextStep }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    console.log(purchaseOrderInformation)
    const initSearchState = {
        checkForSalesOrderProgress: true,
        salesOrderFound: false,
        salesOrderNotFound: false,
        salesOrderMatchFailed: false,
        salesOrderMatchPassed: false,
        multipleSalesOrdersFound: false

    }
    const [searchState, setSearchState] = useState(initSearchState)

    const [databaseSalesOrderInfo, setDatabaseSalesOrderInfo] = useState([])
    
    const handleChange = (event) => {
        const { name, value } = event.target || event
        if(name === 'company_name') {
            setPurchaseOrderInformation((prev) => ({...prev, [name]: value}))
        } else {
            setBuyerInfo((prev) => ({...prev, [name]: value}))
        }
    }

    const checkForSalesOrder = async () => {
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/checkForSalesOrder`, {
                method: 'POST',
                headers: {
                    'Cache-Control': 'no-store',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    purchase_contract_no: purchaseOrderInformation.purchase_contract_no,
                    token: client.token
                })
            })
            await new Promise(r => setTimeout(r, 1500))
            
            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const databaseResponse = await response.json()
            const salesOrderData = databaseResponse.sales_order_data
            const salesOrderDataLength = salesOrderData.length
            console.log(salesOrderDataLength)
            setSearchState((prev) => ({...prev, checkForSalesOrderProgress: false }))
            
            if (salesOrderDataLength === 0) {
                await new Promise(r => setTimeout(r, 500))
                setSearchState((prev) => ({...prev, salesOrderNotFound: true }))
            
            } else if (salesOrderDataLength === 1 && !databaseResponse.data_match) {
                const salesOrder = salesOrderData[0]
                await new Promise(r => setTimeout(r, 500))
                setSearchState((prev) => ({...prev, salesOrderMatchFailed: true, salesOrderFound: true}))
                // setDatabaseSalesOrderInfo((prev) => ({...prev, }))
            
            } else if (salesOrderDataLength === 1 && databaseResponse.data_match) {
                const salesOrder = salesOrderData[0]
                await new Promise(r => setTimeout(r, 500))
                setSearchState((prev) => ({...prev, salesOrderMatchPassed: true, salesOrderFound: true}))
                // setDatabaseSalesOrderInfo((prev) => ({...prev, }))
            
            } else if (salesOrderDataLength > 1) {
                await new Promise(r => setTimeout(r, 500))
                setSearchState((prev) => ({...prev, salesOrderMatchFailed: true, multipleSalesOrdersFound: true}))
            }

            } catch (error) {
                setSearchState((prev) => ({...prev, checkCustomerContactProgress: false}))
                alert(error.message)
            }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        checkForSalesOrder()
        // eslint-disable-next-line
    }, [])
    
    return (
    <>
        <Grid container sx={{ display: 'flex', justifyContent: 'center'}}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                    Checking for an existing Sales Order
                </Typography>
                <Box width={40} height={40} display="flex" alignItems="center">
                    <Fade in={searchState.checkForSalesOrderProgress} unmountOnExit>
                        <CircularProgress size={20} />
                    </Fade>
                    <Fade in={searchState.salesOrderFound} unmountOnExit>
                        <CheckIcon color='success'/>
                    </Fade>
                    <Fade in={searchState.salesOrderNotFound} unmountOnExit>
                        <ErrorOutlineIcon color='error'/>
                    </Fade>
                </Box>
            </Grid>
            {searchState.checkCustomerContactProgress ? (
                <SkeletonComponent />
            ) : (null)}
            {!searchState.checkForSalesOrderProgress && searchState.salesOrderNotFound ? (
                <SalesOrderNotFound
                    client={client}
                    buyerInfo={buyerInfo}
                    purchaseOrderInformation={purchaseOrderInformation}
                    handleChange={handleChange}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ):(null)}
            {!searchState.checkForSalesOrderProgress  && searchState.salesOrderFound && searchState.salesOrderMatchPassed ? (
                <SalesOrderFoundCorrectData
                    client={client}
                    buyerInfo={buyerInfo}
                    purchaseOrderInformation={purchaseOrderInformation}
                    handleChange={handleChange}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)}
            {!searchState.checkForSalesOrderProgress  && searchState.salesOrderFound && searchState.salesOrderMatchFailed ? (
                <SalesOrderFoundUpdateData
                    client={client}
                    buyerInfo={buyerInfo}
                    purchaseOrderInformation={purchaseOrderInformation}
                    handleChange={handleChange}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)}
            {/* {!searchState.checkCustomerContactProgress && searchState.customerContactFailed && searchState.multipleCustomersFound ? (
                <MultipleContactsFound
                    buyerInfo={buyerInfo}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            ) : (null)} */}
        </Grid>
        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'end', paddingTop: 3 }}>
            <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                <Button
                    variant="contained"
                    id="submitButton"
                    size='large'
                    // disabled={purchaseOrderState.pdf == null}
                    sx={{ 
                        backgroundColor: '#5d6c7c',
                        width: '100%',
                        minWidth: '110px',
                        color: 'white',
                        textTransform: 'none',
                        "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                    }}
                    onClick={() => {
                        const button = document.querySelector('#submitButton')
                        button.disabled = true
                        previousStep()
                        setTimeout(() => button.disabled = false, 2000)
                    }}
                >
                    Back
                </Button>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                <Button
                    variant="contained"
                    id="submitButton"
                    size='large'
                    // disabled={purchaseOrderState.pdf == null}
                    sx={{
                        backgroundColor: '#0e4780',
                        color: 'white',
                        width: '100%',
                        minWidth: '110px',
                        textTransform: 'none',
                        "&:hover": { backgroundColor: '#0B3866', color: "white" }
                    }}
                    onClick={() => {
                        const button = document.querySelector('#submitButton')
                        button.disabled = true
                        nextStep()
                        setTimeout(() => button.disabled = false, 2000)
                    }}
                >
                    Continue
                </Button>
            </Grid>
        </Grid>
    </>
    )
}