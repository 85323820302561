import * as React from 'react'
import { Card, CardContent, Typography } from '@mui/material/'
import PurchaseContractHeader from './PurchaseContractHeader'
import PurchaseContractBody from './PurchaseContractBody'

const PurchaseContractInformation = React.memo(({ purchaseOrderInformation, setPurchaseOrderInformation }) => {
    return (
        <Card elevation={1}>
            <CardContent>
                <Typography variant="h6" gutterBottom>Purchase Contract Information</Typography>
                <PurchaseContractHeader purchaseOrderInformation={purchaseOrderInformation} setPurchaseOrderInformation={setPurchaseOrderInformation}/>
                <PurchaseContractBody purchaseOrderInformation={purchaseOrderInformation} setPurchaseOrderInformation={setPurchaseOrderInformation}/>
            </CardContent>
        </Card>
    )
})

export default PurchaseContractInformation