import * as React from 'react'
import { Grid, Fade, Box, Typography, TextField, Button } from '@mui/material/'
import GetRoute from '../../../GetRoute'
import LoadingButton from '@mui/lab/LoadingButton'
import DoneIcon from '@mui/icons-material/Done'

export default function ContactFoundUpdateData({ client, buyerInfo, databaseContactInfo, purchaseOrderInformation, handleChange, previousStep, nextStep }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const [updatingContact, setUpdatingContact] = React.useState(false)
    const [contactUpdateResults, setContactUpdateResults] = React.useState(false)
    const updateContact = async () => {
        setUpdatingContact(true)
        try {
            await client.updateToken(5)
            const response = await fetch(`${protocol}//${routeIP}:8000/utils/updateContact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'  // Added header to prevent caching
                },
                body: JSON.stringify({
                    buyerInfo: buyerInfo,
                    company: purchaseOrderInformation.company_name,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()

            setContactUpdateResults(data.result)
            setUpdatingContact(false)
        } catch (error) {
            setUpdatingContact(false)
            alert(error.message)
            // setPurchaseOrderState(prev => ({...prev, typeError: true, linearProgressToggle: false }))
        }
    }

    return (
            <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
                <Fade
                    in={true}
                    unmountOnExit
                >
                    <Box sx={{ width: '100%' }}>
                        <Typography variant='h6'>
                            Some contact information for <span className="purchaseContactImportance">{buyerInfo.buyer_name}</span> does not match our records.
                        </Typography>
                        <Grid container spacing={1} paddingLeft={2}>
                            <Grid item xs={12}>
                                <Typography variant='body1'>
                                    Please review the conflicting information and decide if you would like to update.
                                </Typography>
                            </Grid>
                            {!(buyerInfo.email === databaseContactInfo.email) ? (
                                <Grid item xs={5}>
                                    <Typography variant='body1' paddingBottom={2}><Typography component="span" variant='h6'>Database Email:</Typography> {databaseContactInfo.email}</Typography>
                                    <TextField
                                        sx={{ width: '80%' }}
                                        label="Purchase Contract Email"
                                        value={buyerInfo.email}
                                        autoComplete='off'
                                        name="email"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            ) : (null)}
                            {!(buyerInfo.phone_number === databaseContactInfo.phone_number) ? (
                                <Grid item xs={5}>
                                    <Typography variant='body1' paddingBottom={2}><Typography component="span" variant='h6'>Database Phone Number:</Typography> {databaseContactInfo.phone_number}</Typography>
                                    <TextField
                                        sx={{ width: '80%' }}
                                        label="Purchase Contract Phone Number"
                                        value={buyerInfo.phone_number}
                                        autoComplete='off'
                                        name="phone_number"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            ) : (null)}
                            {!(purchaseOrderInformation.company_name.toUpperCase() === databaseContactInfo.customer) ? (
                                <Grid item xs={5}>
                                    <Typography variant='body1' paddingBottom={2}><Typography component="span" variant='h6'>Database Customer:</Typography> {databaseContactInfo.customer}</Typography>
                                    <TextField
                                        sx={{ width: '80%' }}
                                        label="Purchase Contract Customer"
                                        value={purchaseOrderInformation.company_name}
                                        autoComplete='off'
                                        name="company_name"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            ) : (null)}
                            {!(buyerInfo.fax === databaseContactInfo.fax) ? (
                                <Grid item xs={5}>
                                    <Typography variant='body1' paddingBottom={2}><Typography component="span" variant='h6'>Database Fax:</Typography> {databaseContactInfo.fax}</Typography>
                                    <TextField
                                        sx={{ width: '80%' }}
                                        label="Purchase Contract Fax"
                                        value={buyerInfo.fax}
                                        autoComplete='off'
                                        name="fax"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            ) : (null)}
                        </Grid>
                        <Grid container paddingTop={2}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>  
                                <Typography variant='h6'>
                                    Would you like to update the contact information?
                                </Typography>
                            </Grid>
                            <Grid item xs={2} paddingLeft={2}>
                                <LoadingButton
                                    loading={updatingContact}
                                    id="updateContact"
                                    disabled={contactUpdateResults}
                                    endIcon={!contactUpdateResults ? (null) : (<DoneIcon sx={{ color: 'grey' }}/>)}
                                    sx={{
                                        padding: 1.2,
                                        color: 'white', 
                                        backgroundColor: '#0e4780',
                                        width: '100%',
                                        minWidth: '123px',
                                        textTransform: 'none',
                                        '&:hover': { cursor: 'pointer', backgroundColor: '#003c6c' }, '& .MuiCircularProgress-root': { color: 'white' } 
                                    }}
                                    onClick={ async () => {
                                        const button = document.querySelector("#updateContact")
                                        button.disabled = true
                                        updateContact()
                                        setTimeout(() => button.disabled = false, 3000)
                                        // await new Promise(r => setTimeout(r, 1000))
                                    }}
                                >
                                    {!contactUpdateResults ? (<p>Update Contact  </p>) : (<Typography color='grey'>Updated</Typography>)}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'end', paddingTop: 3 }}>
                            <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                                <Button
                                    variant="contained"
                                    id="submitButton"
                                    size='large'
                                    // disabled={purchaseOrderState.pdf == null}
                                    sx={{ 
                                        backgroundColor: '#5d6c7c',
                                        width: '100%',
                                        minWidth: '110px',
                                        color: 'white',
                                        textTransform: 'none',
                                        "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                    }}
                                    onClick={() => {
                                        const button = document.querySelector('#submitButton')
                                        button.disabled = true
                                        previousStep()
                                        setTimeout(() => button.disabled = false, 2000)
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                                <Button
                                    variant="contained"
                                    id="submitButton"
                                    size='large'
                                    // disabled={purchaseOrderState.pdf == null}
                                    sx={{
                                        backgroundColor: '#0e4780',
                                        color: 'white',
                                        width: '100%',
                                        minWidth: '110px',
                                        textTransform: 'none',
                                        "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                    }}
                                    onClick={() => {
                                        const button = document.querySelector('#submitButton')
                                        button.disabled = true
                                        nextStep()
                                        setTimeout(() => button.disabled = false, 2000)
                                    }}
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Grid>
    )
}