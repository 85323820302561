import * as React from 'react'
import { useEffect } from "react"
import { Grid, Typography, Button } from '@mui/material/'
import BuyerInformation from './BuyerInformation'
import ItemAccordion from './PurchaseContractItems/ItemAccordion'
import PurchaseContractInformation from './PurchaseContractInformation/PurchaseContractInformation'
import PCAttachmentsTable from './PCAttachmentsTable'

export default function VerifyInformation({ purchaseOrderInformation, setPurchaseOrderInformation, purchaseOrderItems, setPurchaseOrderItems, buyerInfo, setBuyerInfo,
    purchaseOrderAttachments, setPurchaseOrderAttachments, previousStep, nextStep }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Typography variant='h4'>Purchase Contract/Purchase Contract Change</Typography>
                <Grid item xs={12}>
                    <PurchaseContractInformation
                        purchaseOrderInformation={purchaseOrderInformation}
                        setPurchaseOrderInformation={setPurchaseOrderInformation}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ItemAccordion
                        purchaseOrderItems={purchaseOrderItems}
                        setPurchaseOrderItems={setPurchaseOrderItems}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PCAttachmentsTable
                        purchaseOrderAttachments={purchaseOrderAttachments}
                        setPurchaseOrderAttachments={setPurchaseOrderAttachments}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BuyerInformation
                        buyerInfo={buyerInfo}
                        setBuyerInfo={setBuyerInfo}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'end', padding: 1 }}>
                <Grid item xs={1.5} sx={{ display: 'flex', justifyItems: 'end' }}>
                    <Button
                        variant="contained"
                        id="submitButton"
                        size='large'
                        // disabled={purchaseOrderState.pdf == null}
                        sx={{ 
                            backgroundColor: '#5d6c7c',
                            width: '100%',
                            minWidth: '123px',
                            color: 'white',
                            textTransform: 'none',
                            "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                        }}
                        onClick={() => {
                            const button = document.querySelector('#submitButton')
                            button.disabled = true
                            previousStep()
                            setTimeout(() => button.disabled = false, 2000)
                        }}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={1.5} sx={{ display: 'flex', justifyItems: 'end' }}>
                    <Button
                        variant="contained"
                        id="submitButton"
                        size='large'
                        // disabled={purchaseOrderState.pdf == null}
                        sx={{
                            backgroundColor: '#0e4780',
                            color: 'white',
                            width: '100%',
                            minWidth: '123px',
                            textTransform: 'none',
                            "&:hover": { backgroundColor: '#0B3866', color: "white" }
                        }}
                        onClick={() => {
                            const button = document.querySelector('#submitButton')
                            button.disabled = true
                            nextStep()
                            setTimeout(() => button.disabled = false, 2000)
                        }}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}