import * as React from 'react'
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Typography, Paper, TextField, Card, CardContent } from '@mui/material/'

const PCAttachmentRow = React.memo(({ index, row, handleChange }) => {
    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" sx={{ width: '15%'}}>
                <TextField
                    variant="standard"
                    autoComplete="off"
                    value={row.pc_attachment || ''}
                    onChange={(e) => handleChange(index, 'pc_attachment', e)}
                />
            </TableCell>
            <TableCell align="left">
                <TextField
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    value={row.description || ''}
                    onChange={(e) => handleChange(index, 'description', e)}
                />
            </TableCell>
        </TableRow>
    )
}, (prevProps, nextProps) => {
    return prevProps.row.pc_attachment === nextProps.row.pc_attachment &&
        prevProps.row.description === nextProps.row.description &&
        prevProps.index === nextProps.index;
})

const PCAttachmentsTable = React.memo(({ purchaseOrderAttachments, setPurchaseOrderAttachments }) => {

    const handleChange = React.useCallback((index, field, event) => {
        const { value } = event.target
        setPurchaseOrderAttachments((prev) => {
            // Create a new array with updated value for the specific row and field
            return prev.map((row, i) =>
                i === index ? { ...row, [field]: value } : row
            )
        })
    }, [setPurchaseOrderAttachments])

    return (
        <Card elevation={1}>
            <CardContent>
                <Typography variant='h6'>PC Attachment(s)</Typography>
                <TableContainer component={Paper} sx={{ height: '300px'}}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>PC Attachment</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseOrderAttachments.map((row, index) => (
                                <PCAttachmentRow key={index} index={index} row={row} handleChange={handleChange} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
})


export default PCAttachmentsTable