import * as React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography, Card, CardContent, Grid, TextField, Box, InputAdornment } from '@mui/material/'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ItemScheduleTable from './ItemScheduleTable'
import ItemAttachmentsTable from './ItemAttachmentsTable'

const MemoizedAccordionItem = React.memo(({ item, index, expanded, handleAccordionChange, purchaseOrderItems, setPurchaseOrderItems, handleTextFieldChange }) => {
    const isExpanded = expanded === `Index: ${index}`
    return (
        <Accordion expanded={expanded === `Index: ${index}`} onChange={handleAccordionChange(`Index: ${index}`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ width: '20%' }}>
                    <TextField
                        size="small"
                        label="Item"
                        variant="standard"
                        autoComplete="off"
                        name="item"
                        value={item['item'] || ''}
                        onChange={(e) => handleTextFieldChange(index, e)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Box>
                <Box sx={{ width: '20%' }}>
                    <TextField
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        label="Part Number"
                        variant="standard"
                        autoComplete="off"
                        name="part_number"
                        value={item['part_number'] || ''}
                        onChange={(e) => handleTextFieldChange(index, e)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Box>
                <Box sx={{ width: '20%' }}>
                    <TextField
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        label="UM Ordered"
                        variant="standard"
                        autoComplete="off"
                        name="um_ordered"
                        value={item['um_ordered'] || ''}
                        onChange={(e) => handleTextFieldChange(index, e)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Box>
                <Box sx={{ width: '15%' }}>
                    <TextField
                        size="small"
                        sx={{ color: 'text.secondary' }}
                        label="Unit Price"
                        variant="standard"
                        autoComplete="off"
                        name="unit_price"
                        value={item['unit_price'] || ''}
                        onChange={(e) => handleTextFieldChange(index, e)}
                        onClick={(e) => e.stopPropagation()}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                    />
                </Box>
            </AccordionSummary>
            {isExpanded && (
                <AccordionDetails sx={{ backgroundColor: 'gainsboro', paddingTop: 2, paddingLeft: 3, paddingRight: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Description"
                                variant="standard"
                                autoComplete="off"
                                name="description"
                                value={item['description'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <TextField
                                label="Total Qty Ordered"
                                variant="standard"
                                autoComplete="off"
                                name="total_qty_ordered"
                                value={item['total_qty_ordered'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <TextField
                                label="Vendor PN"
                                variant="standard"
                                autoComplete="off"
                                name="vendor_pn"
                                value={item['vendor_pn'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <TextField
                                label="issuing_loc"
                                variant="standard"
                                autoComplete="off"
                                name="issuing_loc"
                                value={item['issuing_loc'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <TextField
                                label="Item Ext Amount"
                                variant="standard"
                                autoComplete="off"
                                name="item_ext_amount"
                                value={item['item_ext_amount'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                            />
                        </Grid>
                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'evenly-spaced' }}>
                            <TextField
                                label="Customer Contract"
                                variant="standard"
                                autoComplete="off"
                                name="customer_contract"
                                value={item['customer_contract'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'evenly-spaced' }}>
                            <TextField
                                label="Prime Contract"
                                variant="standard"
                                autoComplete="off"
                                name="prime_contract"
                                value={item['prime_contract'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'evenly-spaced' }}>
                            <TextField
                                label="Customer Order"
                                variant="standard"
                                autoComplete="off"
                                name="customer_order"
                                value={item['customer_order'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={2.4} sx={{ display: 'flex', justifyContent: 'evenly-spaced' }}>
                            <TextField
                                label="Priority Rating"
                                variant="standard"
                                autoComplete="off"
                                name="priority_rating"
                                value={item['priority_rating'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={2.1} sx={{ display: 'flex', justifyContent: 'end' }}>
                            <TextField
                                label="Qty"
                                variant="standard"
                                autoComplete="off"
                                name="qty"
                                value={item['qty'] || ''}
                                onChange={(e) => handleTextFieldChange(index, e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ItemScheduleTable
                                itemIndex={index}
                                purchaseOrderItems={purchaseOrderItems}
                                setPurchaseOrderItems={setPurchaseOrderItems}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ paddingLeft: 2 }}>
                                <Typography variant='h6'>Ship To</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name="ship_to"
                                    variant="standard"
                                    autoComplete="off"
                                    value={item['ship_to'] || ''}
                                    onChange={(e) => handleTextFieldChange(index, e)}
                                />
                                <TextField
                                    label="Mark For"
                                    variant="standard"
                                    autoComplete="off"
                                    name="mark_for"
                                    value={item['mark_for'] || ''}
                                    sx={{ marginTop: 1, display: 'flex', width: '50%' }}
                                    onChange={(e) => handleTextFieldChange(index, e)}
                                />
                                <TextField
                                    label="Deliver To Location"
                                    variant="standard"
                                    autoComplete="off"
                                    name="deliver_to_location"
                                    value={item['deliver_to_location'] || ''}
                                    sx={{ marginTop: 1, display: 'flex', width: '50%' }}
                                    onChange={(e) => handleTextFieldChange(index, e)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <ItemAttachmentsTable
                                itemIndex={index}
                                purchaseOrderItems={purchaseOrderItems}
                                setPurchaseOrderItems={setPurchaseOrderItems}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            )}
        </Accordion>
    )
})

const ItemAccordion = React.memo(({ purchaseOrderItems, setPurchaseOrderItems }) => {
    const [expanded, setExpanded] = React.useState(false)

    const handleAccordionChange = React.useCallback((panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }, [])

    const handleTextFieldChange = React.useCallback((index, event) => {
        const { name, value } = event.target
        
        setPurchaseOrderItems(prevItems => {
            const newItems = [...prevItems]
            newItems[index] = { ...newItems[index], [name]: value }
            return newItems
        })
    }, [setPurchaseOrderItems]) // Memoized based on setPurchaseOrderItems
    
    

    return (
        <Card elevation={1}>
            <CardContent>
                <Typography variant="h6" gutterBottom>Purchase Contract Items</Typography>
                {purchaseOrderItems.map((item, index) => (
                    <MemoizedAccordionItem
                        key={index}
                        item={item}
                        index={index}
                        expanded={expanded}
                        handleAccordionChange={handleAccordionChange}
                        purchaseOrderItems={purchaseOrderItems}
                        setPurchaseOrderItems={setPurchaseOrderItems}
                        handleTextFieldChange={handleTextFieldChange}
                    />
                ))}
            </CardContent>
        </Card>
    )
})

export default ItemAccordion
