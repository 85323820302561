import * as React from 'react'
import { useEffect } from "react"
import { Typography, Grid, Button, List, ListItem } from '@mui/material/'
import GetRoute from '../../../GetRoute'
import DropZone from '../../../DropZone'

export default function InputPurchaseOrder({ 
    client,
    purchaseOrderState,
    setPurchaseOrderState,
    initPoState,
    setPurchaseOrderInformation,
    setPurchaseOrderItems,
    setPurchaseOrderAttachments,
    setBuyerInfo,
    nextStep }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    
    const onSubmit = async () => {
        setPurchaseOrderState(prev => ({...prev, linearProgressToggle: true}))
        try {
            await client.updateToken(5)
            if (purchaseOrderState.pdf.has('token')) {
                purchaseOrderState.pdf.delete('token')
                purchaseOrderState.pdf.append('token', client.token)
            } else if (!purchaseOrderState.pdf.has('token')) {
                purchaseOrderState.pdf.append('token', client.token)
            }
            // const refreshed = await client.updateToken(5)
            // if (refreshed && purchaseOrderState.pdf.has('token')) {
            //     purchaseOrderState.pdf.delete('token')
            //     purchaseOrderState.pdf.append('token', client.token)
            // } else if (!purchaseOrderState.pdf.has('token')) {
            //     purchaseOrderState.pdf.append('token', client.token)
            // }

            const response = await fetch(`${protocol}//${routeIP}:8000/utils/getPurchaseOrderData`, {
                method: 'POST',
                headers: {
                    'Cache-Control': 'no-store'  // Added header to prevent caching
                },
                body: purchaseOrderState.pdf
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            setPurchaseOrderState(prev => ({...prev, linearProgressToggle: false }))
            setPurchaseOrderInformation(data['pc_information'])
            setPurchaseOrderItems(data['purchase_order_items'])
            setPurchaseOrderAttachments(data['pc_attachments'])
            setBuyerInfo(data['buyer_information'])
            nextStep()
        } catch (error) {
            alert(error.message)
            setPurchaseOrderState(prev => ({...prev, typeError: true, linearProgressToggle: false }))
        }
    }

    return (
        <Grid item xs={10} sx={{ paddingTop: 1, paddingBottom: 2 }}>
            <Typography variant="h5">Input Boeing Purchase Contract</Typography>
            <List sx={{ paddingBottom: 3 }}>
                <ListItem sx={{ paddingTop: 0, paddingBottom : 0 }}>
                    <Typography variant='subtitle1'>Must be a Boeing Purchase Order.</Typography>
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom : 0 }}>
                    <Typography variant='subtitle1'>File must be a PDF.</Typography>
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom : 0 }}>
                    <Typography variant='subtitle1'>Only one PDF is allowed.</Typography>
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom : 0 }}>
                    <Typography variant='subtitle1'>Ensure it's the original PDF. Scanned or written over PDF's will not work.</Typography>
                </ListItem>
            </List>
            <DropZone
                purchaseOrderState={purchaseOrderState}
                setPurchaseOrderState={setPurchaseOrderState}
                initState={initPoState}
                fileTypes={['application/pdf']}
                dropZoneText={"Drag 'n' drop a Boeing PDF file here, or click to select Boeing PDF file"}
            />
            {/* <Grid container sx={{ display: 'flex', justifyItems: 'end' }}>
                <Grid item xs={3} sx={{ display: 'flex', justifyItems: 'end' }}>
                    <Button
                        variant="contained"
                        id="submitButton"
                        size='large'
                        // disabled={purchaseOrderState.pdf == null}
                        sx={{
                            backgroundColor: '#0e4780',
                            color: 'white',
                            width: '100%',
                            minWidth: '123px',
                            textTransform: 'none',
                            "&:hover": { backgroundColor: '#0B3866', color: "white" }
                        }}
                        onClick={() => {
                            const button = document.querySelector('#submitButton')
                            button.disabled = true
                            nextStep()
                            setTimeout(() => button.disabled = false, 2000)
                        }}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid> */}
            <Grid container sx={{ display: 'flex', justifyContent: 'end', marginTop: 2 }}>
                <Grid item xs={3.5}>
                    <Button
                        variant="contained"
                        id="submitButton"
                        size='large'
                        disabled={purchaseOrderState.pdf == null}
                        sx={{
                            backgroundColor: '#0e4780',
                            color: 'white',
                            width: '100%',
                            minWidth: '123px',
                            textTransform: 'none',
                            "&:hover": { backgroundColor: '#0B3866', color: "white" }
                        }}
                        onClick={() => {
                            const button = document.querySelector('#submitButton')
                            button.disabled = true
                            onSubmit()
                            setTimeout(() => button.disabled = false, 2000)
                        }}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}