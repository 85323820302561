import * as React from 'react'
import { useState } from "react"
import { Typography, Grid, Box, Paper } from '@mui/material/'
import LinearProgress from '@mui/material/LinearProgress'
import VerticalStepper from './VerticalStepper'
import InputPurchaseOrder from './Steps/InputPurchaseOrder/InputPurchaseOrder'
import VerifyInformation from './Steps/VerifyInformation/VerifyInformation'
import CheckContactInformation from './Steps/CheckContactInformation/CheckContactInformation'
import CheckSalesOrderInformation from './Steps/CheckSalesOrder/CheckSalesOrder'

export default function BoeingPCImporter({ client }) {
    const initPoState = {
        pdf: null,
        fileName: "",
        size: null,
        activeStep: 0,
        typeError: false,
        linearProgressToggle: false
    }
    const [purchaseOrderState, setPurchaseOrderState] = useState(initPoState)

    const [purchaseOrderInformation, setPurchaseOrderInformation] = useState({})
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [purchaseOrderAttachments, setPurchaseOrderAttachments] = useState([])
    const [buyerInfo, setBuyerInfo] = useState({
        buyer_name: "",
        email: "",
        fax: "",
        loc_bldg_ms: "",
        phone_number: ""
    })

    const nextStep = () => {
        setPurchaseOrderState((prev) => ({...prev, activeStep: prev.activeStep + 1}))
    }

    const previousStep = () => {
        setPurchaseOrderState((prev) => ({...prev, activeStep: prev.activeStep - 1}))
    }

    // const handleReset = () => {
    //     setPurchaseOrderState((prev) => ({...prev, activeStep: 0}))
    // }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginInline: 'auto',
                minHeight: 'calc(100vh - 63px)',
                maxWidth: '1920px',
                padding: 1
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4.5} lg={3.5} xl={2.5} sx={{ height: '99.9%' }}>
                    <Paper 
                        elevation={4} 
                        sx={{
                            height: 'calc(100vh - 80px)', 
                            padding: 1, 
                            paddingTop: 1 + '4px', 
                            position: 'sticky', // Make the stepper sticky
                            top: 72, // Adjust the value based on how much space you want from the top
                            zIndex: 1000, // Ensure the stepper stays above other content
                        }}
                    >
                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                            <Typography gutterBottom variant="h4" sx={{ textAlign: 'center', color:'white', padding: 1 }}>Progress Bar</Typography>
                        </Paper>
                        <VerticalStepper activeStep={purchaseOrderState.activeStep} purchaseOrderState={purchaseOrderState}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ height: '100%' }}>
                    <Grid container sx={{ flexDirection: 'column', height: '100%' }}>
                        <Paper elevation={4} sx={{ justifyItems: 'center', height: '100%' }}>
                            <Grid item xs={10} sx={{ padding: 1, width: '100%' }}>
                                <Box>
                                    {purchaseOrderState.linearProgressToggle === true ? (
                                        <Box sx={{ width: '100%' }}>
                                                <LinearProgress />
                                            </Box>
                                        ) : (
                                            <Box sx={{ width: '100%', minHeight: '4px' }}/>
                                        )}
                                    <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center', color:'white', padding: 1 }}>Boeing Purchase Contract Importer</Typography>
                                    </Paper>
                                </Box>
                            </Grid>
                            {purchaseOrderState.activeStep === 0 && (
                                <InputPurchaseOrder
                                    client={client}
                                    purchaseOrderState={purchaseOrderState}
                                    setPurchaseOrderState={setPurchaseOrderState}
                                    initPoState={initPoState}
                                    setPurchaseOrderInformation={setPurchaseOrderInformation}
                                    setPurchaseOrderItems={setPurchaseOrderItems}
                                    setPurchaseOrderAttachments={setPurchaseOrderAttachments}
                                    setBuyerInfo={setBuyerInfo}
                                    nextStep={nextStep}
                                />
                            )}
                            {purchaseOrderState.activeStep === 1 &&  (
                                <VerifyInformation
                                    purchaseOrderInformation={purchaseOrderInformation}
                                    setPurchaseOrderInformation={setPurchaseOrderInformation}
                                    purchaseOrderItems={purchaseOrderItems}
                                    setPurchaseOrderItems={setPurchaseOrderItems}
                                    purchaseOrderAttachments={purchaseOrderAttachments}
                                    setPurchaseOrderAttachments={setPurchaseOrderAttachments}
                                    buyerInfo={buyerInfo}
                                    setBuyerInfo={setBuyerInfo}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            )}
                            {purchaseOrderState.activeStep === 2 && (
                                <CheckContactInformation
                                    client={client}
                                    purchaseOrderInformation={purchaseOrderInformation}
                                    setPurchaseOrderInformation={setPurchaseOrderInformation}
                                    purchaseOrderItems={purchaseOrderItems}
                                    purchaseOrderAttachments={purchaseOrderAttachments}
                                    buyerInfo={buyerInfo}
                                    setBuyerInfo={setBuyerInfo}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            )}
                            {purchaseOrderState.activeStep === 3 && (
                                <CheckSalesOrderInformation
                                    client={client}
                                    purchaseOrderInformation={purchaseOrderInformation}
                                    setPurchaseOrderInformation={setPurchaseOrderInformation}
                                    purchaseOrderItems={purchaseOrderItems}
                                    purchaseOrderAttachments={purchaseOrderAttachments}
                                    buyerInfo={buyerInfo}
                                    setBuyerInfo={setBuyerInfo}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={.5} lg={.5} xl={.5}/>
            </Grid>
        </Box>
    )
}