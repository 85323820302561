import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material/'
import Vision from './images/vision.png'
import Productivity from './images/productivity.png'
import Milestone from './images/milestone.png'
import Success from './images/success.png'

const Goals = () => {

    return (
        <Grid container
            sx={{
                width: '100%',
                maxWidth: '1920px',
                display: 'flex',
                alignItems: 'flex-start',
                marginInline: 'auto',
                backgroundColor: 'white'
            }}
        >
            <Grid item xs={12} sx={{ width: '100%', background: 'linear-gradient(to bottom, #002233 30%, white 70%)', padding: 1 }} />
            <Grid item md={0} lg={1}/>
            <Grid item xs={12} sm={6} md={6} lg={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card variant="plain" sx={{ maxWidth: 345, backgroundColor: 'inherit' }}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            height="200"
                            image={Vision}
                            alt="create account"
                        />
                        <Typography gutterBottom variant="h5" component="div" color="#1d9fdd" sx={{ textAlign: 'center' }}>
                        Gain Visibility
                        </Typography>
                        <Typography variant="body2" color="#203b72" sx={{ textAlign: 'center' }}>
                        Gather and visualize the data that was previously uncollected
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card variant="plain" sx={{ maxWidth: 345, backgroundColor: 'inherit' }}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            height="200"
                            image={Productivity}
                            alt="create account"
                        />
                        <Typography gutterBottom variant="h5" component="div" color="#1d9fdd" sx={{ textAlign: 'center' }}>
                        Streamline Production
                        </Typography>
                        <Typography variant="body2" color="#203b72" sx={{ textAlign: 'center'}}>
                        Use this data to identify inefficiencies in the processes and optimize manufacturing flow
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card variant="plain" sx={{ maxWidth: 345, backgroundColor: 'inherit' }}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            height="200"
                            image={Milestone}
                            alt="create account"
                        />
                        <Typography gutterBottom variant="h5" component="div" color="#1d9fdd" sx={{ textAlign: 'center' }}>
                        Achieve Milestones
                        </Typography>
                        <Typography variant="body2" color="#203b72" sx={{ textAlign: 'center'}}>
                        Addressing newly identified inefficiencies with data enables meeting projected targets earlier
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card variant="plain" sx={{ maxWidth: 345, backgroundColor: 'inherit' }}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            height="200"
                            image={Success}
                            alt="create account"
                        />
                        <Typography gutterBottom variant="h5" component="div" color="#1d9fdd" sx={{ textAlign: 'center' }}>
                            Succeed
                        </Typography>
                        <Typography variant="body2" color="#203b72" sx={{ textAlign: 'center' }}>
                            Aim for the stars and land on the moon with Apollo Software Solutions
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        <Grid item md={0} lg={1}/>
        <Grid item xs={12} sx={{ width: '100%', backgroundColor: 'white', padding: 1 }} />
        </Grid>
    )
}

export default Goals