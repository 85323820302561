import * as React from 'react'
import { Grid, TextField, InputAdornment } from '@mui/material/'

const PurchaseContractHeader = React.memo(({ purchaseOrderInformation, setPurchaseOrderInformation }) => {
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setPurchaseOrderInformation((prev) => ({...prev, [name]: value}))
    }

    return (
        <Grid container sx={{ border: '1px solid black', padding: 1 }}>
            <Grid item xs={4} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Purchase Contract No"
                    name="purchase_contract_no"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.purchase_contract_no || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Purchase Contract Change No"
                    name="purchase_contract_change_no"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.purchase_contract_change_no || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4} sx={{ padding: .5, display: 'flex', justifyContent: 'end' }}>
                <TextField
                    label="PC/PCC Date"
                    name="pc_ppc_date"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.pc_ppc_date || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Total PC Value"
                    name="total_pc_value"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.total_pc_value || ''}
                    onChange={handleChange}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                />
            </Grid>
            <Grid item xs={6} sx={{ padding: .5, display: 'flex', justifyContent: 'end' }}>
                <TextField
                    label="PC Orig Date"
                    name="pc_orig_date"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.pc_orig_date || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Total PC Funding"
                    name="total_pc_funding"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.total_pc_funding || ''}
                    onChange={handleChange}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                />
            </Grid>
            <Grid item xs={4} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Total Definitized Value"
                    name="total_definitized_value"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.total_definitized_value || ''}
                    onChange={handleChange}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                />
            </Grid>
            <Grid item xs={8} sx={{ padding: .5, display: 'flex', justifyContent: 'start' }}>
                <TextField
                    label="Total Undefinitized NTE Value"
                    name="total_undefinitized_nte_value"
                    variant="standard"
                    autoComplete="off"
                    value={purchaseOrderInformation.total_undefinitized_nte_value || ''}
                    onChange={handleChange}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                />
            </Grid>
        </Grid>
    )   
})

export default PurchaseContractHeader