import * as React from 'react'
import { Grid, Fade, Box, Skeleton } from '@mui/material/'

export default function SkeletonComponent() {
    return (
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', padding: 1, height: '400px' }}>
            <Fade
                in={true}
                unmountOnExit
                sx={{ width: '100%' }}
            >
                <Box>
                    <Skeleton sx={{ width: '100%', height: '60px' }}/>
                    <Skeleton sx={{ width: '100%' }}/>
                    <Skeleton sx={{ width: '100%', height: '60px' }}/>
                    <Skeleton sx={{ width: '100%' }}/>
                    <Skeleton sx={{ width: '100%', height: '60px' }}/>
                    <Skeleton sx={{ width: '100%' }}/>
                    <Skeleton sx={{ width: '100%', height: '60px' }}/>
                    <Skeleton sx={{ width: '100%' }}/>
                    <Skeleton sx={{ width: '100%', height: '60px' }}/>
                </Box>
            </Fade>
        </Grid>
    )
}