import { useEffect, useRef } from 'react'

const useIdleRedirect = (handleIdle) => {
    const idleTimeoutRef = useRef(null)

    const resetIdleTimeout = () => {
        const idleDurationSecs = 60 // X number of seconds
        // Clears the existing timeout
        if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current)
    
        // Set a new idle timeout to load the redirectUrl after idleDurationSecs
        idleTimeoutRef.current = setTimeout(() => handleIdle(), idleDurationSecs * 1000)
    }

    useEffect(() => {
        const events = ['click', 'touchstart', 'mousemove', 'keydown']
        // Reset the idle timeout on any of the events listed
        const handleEvent = () => resetIdleTimeout()
        events.forEach(evt => 
            document.addEventListener(evt, handleEvent, false)
        )
        // Set the initial idle timeout
        resetIdleTimeout()
        // Cleanup the event listeners on unmount
        return () => {
            events.forEach(evt => 
                document.removeEventListener(evt, handleEvent, false)
            )
            if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current)
        }
        // eslint-disable-next-line
    }, [])

}

export default useIdleRedirect