import * as React from 'react'
import { TextField, Typography, Grid, Card, CardContent } from '@mui/material/'
import validator from 'validator'

const BuyerInformation = React.memo(({ buyerInfo, setBuyerInfo }) => {

    const handleChange = (event) => {
        const { name, value } = event.target || event;
        setBuyerInfo((prev) => ({...prev, [name]: value}))
    }

    return (
        <Card elevation={1}>
            <CardContent>
            <Typography variant="h6" gutterBottom>Buyer Information</Typography>
                <Grid container>
                    <Grid item xs={3} sx={{ padding: 1, display: 'flex', justifyContent: 'start' }}>
                        <TextField
                            fullWidth
                            label="Buyer Name"
                            name="buyer_name"
                            variant="standard"
                            autoComplete="off"
                            value={buyerInfo.buyer_name || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={3} sx={{ padding: 1, display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                        <TextField 
                            autoComplete="new-password"
                            fullWidth
                            label="Phone Number"
                            name="phone_number"
                            variant="standard"
                            value={buyerInfo['phone_number'] || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ padding: 1 }}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            variant="standard"
                            autoComplete="off"
                            value={buyerInfo['email'] || ''}
                            error={!(validator.isEmail(buyerInfo['email'])) && buyerInfo['email'] !== ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={3} sx={{ padding: 1, display: 'flex', justifyContent: 'end' }}>
                        <TextField
                            fullWidth
                            label="Fax"
                            name="fax"
                            variant="standard"
                            autoComplete="off"
                            value={buyerInfo['fax'] || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ padding: 1 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            label="Loc/Bldg/Ms"
                            name="loc_bldg_ms"
                            variant="standard"
                            autoComplete="off"
                            value={buyerInfo['loc_bldg_ms'] || ''}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
})

export default BuyerInformation