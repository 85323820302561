import React from 'react';
import Snowfall from 'react-snowfall'
import { Box, Divider } from '@mui/material/'
import Banner from './Banner.js'
import Goals from './Goals.js'
import FeatureList from './features/FeatureList'


const Home = () => {
  //Used for the React snowfall effect during the snow seasons
  const monthsItSnows = [11,0,1]
  const date = new Date()
  
  return (
    <Box>
      {monthsItSnows.includes(date.getMonth()) ? (
        <Snowfall />
      ) : (<React.Fragment></React.Fragment>)}
      <Banner/>
      <Goals/>
      <Divider
        variant="middle"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1920px',
          marginInline: 'auto'
        }}
      />
      <FeatureList/>
    </Box>
  )
}

export default Home