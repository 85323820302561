import React from 'react'
import { useState, useEffect } from 'react'
import { Paper, Grid, Typography, Box, FormControl, FormControlLabel, FormGroup, InputLabel, Select, MenuItem, Button, FormHelperText, Checkbox, Divider } from '@mui/material/'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LinearProgress from '@mui/material/LinearProgress'
import parseReportData from './ParseData/parseReportData'
import MachineReportDialog from './MachineReportDialog'
import { getMachineList, generateReportDataOptions } from './utils'
import GetRoute from '../GetRoute'
import axios from 'axios'
import dayjs from 'dayjs'

dayjs.extend(utc)
dayjs.extend(timezone)

const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxHeight: 400,
            width: 100,
        },
    },
}

const ReportGeneration = ({ client }) => {

    const initialState = {
        reportType: "",
        reportFetchResult: [],
        itemsToGet: [],
        startDate: dayjs().subtract(1, 'day'),
        endDate: dayjs(new Date()),
        reportErrorValue: false,
        itemsToGetErrorValue: false,
        linearProgressToggle: false,
        programTableToggle: false,
        errorTableToggle: false,
        dailyRecordsToggle: false,
        dailyRecordsBarGraph: false,
        dailyRecordHLATable: false,
        dailyRecordHLABarGraph: false,
        AverageOperatingTimePieChart: false,
        groupOverview: false,
        excludeWeekend: false,
        reportDialogToggle: false

    }

    const reportValuesInitState = {
        ScopeObject: {
            fetchDepartments: "Department Report",
            fetchMachines: "Machine Report",
            fetchBuildings: "Building Report",
            allMachines: "Full Machine Report"
        },
        generatedReportOptions: null,
        machineNameString: "",
    }

    const parsedDataInitState = {
        groupOverview: {},
        machines: []
    }

    const [generateFormValues, setGenerateFormValues] = useState(initialState)
    const [parsedData, setParsedData] = useState(parsedDataInitState)    //This useState stores the data 
    const [report, setReport] = useState(reportValuesInitState)
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const fetchReport = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/fetchReport`, {
                reportType: generateFormValues.reportType,
                itemsToGet: generateFormValues.itemsToGet,
                excludeWeekend: generateFormValues.excludeWeekend,
                startDate: generateFormValues.startDate['$d'],
                endDate: generateFormValues.endDate['$d'],
                token: client.token
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store, must-revalidate', // Forces validation with the server, but can use cached data
                }
            })

            setParsedData(parseReportData(response.data, generateFormValues))
        } catch (error) {
            alert(error)
        }
    }


    const handleSubmit = async () => {
        setReport(reportValuesInitState)
        if (generateFormValues.reportType !== "") {
            setGenerateFormValues(prev => ({...prev, reportErrorValue: false}))
        } 
        if (generateFormValues.reportType === "") {
            alert("Scope field is empty! This field is required.")
            setGenerateFormValues(prev => ({...prev, reportErrorValue: true}))
            return

        }
        if (generateFormValues.reportType !== `allMachines` && generateFormValues.itemsToGet.length === 0) {
            alert("You must select a report option unless selecting All Machines for your scope.")
            setGenerateFormValues(prev => ({...prev, itemsToGetErrorValue: true}))
            return
        }
        
        setGenerateFormValues(prev => ({...prev, linearProgressToggle: true})) // Show the progress bar
        await fetchReport() // Wait for fetch to complete
        setGenerateFormValues(prev => ({...prev, reportDialogToggle: true})) // Hide the progress bar
        setGenerateFormValues(prev => ({...prev, linearProgressToggle: false}))
    }

    const handleReset = () => {
        setGenerateFormValues(initialState)
        setParsedData(parsedDataInitState)
        setReport(reportValuesInitState)
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setParsedData(parsedDataInitState)
        if (name === 'reportType') {
            setGenerateFormValues(prev => ({...prev, itemsToGet: []}))
        }
        setGenerateFormValues(prev => ({...prev, [name]: value}))
    }

    const generateItems = async () => {
        if (generateFormValues.reportType !== "" && generateFormValues.reportType !== "allMachines") {
            try {
                await client.updateToken(5)
                const response = await axios.post(`${protocol}//${routeIP}:8000/utils/${generateFormValues.reportType}`, {
                    token: client.token
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-store'
                    }
                })

                setGenerateFormValues(prev => ({...prev, reportFetchResult: response.data}))
            } catch (error) {
                alert(error)
            }
        }
    }
    
    
    useEffect(() => {
        generateItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [generateFormValues.reportType])


    useEffect(() => {
        if (parsedData.machines.length > 0) {
            const genReportOptions = generateReportDataOptions(parsedData, generateFormValues)
            const machineNames = getMachineList(parsedData.machines)
            setReport(prev => ({...prev, generatedReportOptions: genReportOptions, machineNameString: machineNames}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData])

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 329px)' }}>
            <Paper elevation={8} sx={{ marginTop: 5 }}>
                {generateFormValues.linearProgressToggle === true ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (<Box sx={{ width: '100%', minHeight: '4px' }}></Box>)}
                <Grid container sx={{ justifyContent: 'space-evenly', paddingTop: 2, paddingBottom: 5 }}>
                    <Grid item xs={12} sx={{ margin: 1 }}>
                        <Typography gutterBottom variant="h4" sx={{ textAlign: 'center' }}>
                            Report Generation
                        </Typography>
                    </Grid>
                    { generateFormValues.reportType === `allMachines` ? (
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'center', paddingBottom: 5 }} >
                            <Grid container 
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant='h6' textAlign='center'>Report Options</Typography>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Scope</InputLabel>
                                        <Select
                                            value={generateFormValues.reportType}
                                            labelId="Scope"
                                            id="Scope"
                                            label="Scope"
                                            name="reportType"
                                            onChange={handleChange}
                                            required
                                            error={ generateFormValues.reportErrorValue === true && generateFormValues.reportType === [] }
                                        >
                                            <MenuItem value={`fetchDepartments`}>Department</MenuItem>
                                            <MenuItem value={`fetchMachines`}>Machine</MenuItem>
                                            <MenuItem value={`fetchBuildings`}>Building</MenuItem>
                                            <MenuItem value={`allMachines`}>All Machines</MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Specific item(s)</InputLabel>
                                        <Select
                                            sx={{ height: '56px' }}
                                            value={generateFormValues.itemsToGet}
                                            labelId="items"
                                            id="items"
                                            label="Specific item(s)"
                                            multiple
                                            name="itemsToGet"
                                            MenuProps={MenuProps}
                                            onChange={handleChange}
                                            // renderValue={(selected) => selected.join(', ')}
                                            error={ generateFormValues.itemsToGetErrorValue === true }
                                            disabled
                                        >
                                            { generateFormValues.reportFetchResult.map( item => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox checked={generateFormValues.itemsToGet.indexOf(item) > -1}/>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText>Required (Unless All Machines is selected)</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                            <Grid container 
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={4}>
                                    <Typography gutterBottom variant='h6' textAlign='center'>Report Options</Typography>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Scope</InputLabel>
                                        <Select
                                            value={generateFormValues.reportType}
                                            labelId="Scope"
                                            id="Scope"
                                            label="Scope"
                                            name="reportType"
                                            onChange={handleChange}
                                            required
                                            error={ generateFormValues.reportErrorValue === true && generateFormValues.reportType === "" }
                                        >
                                            <MenuItem value={`fetchDepartments`}>Department</MenuItem>
                                            <MenuItem value={`fetchMachines`}>Machine</MenuItem>
                                            <MenuItem value={`fetchBuildings`}>Building</MenuItem>
                                            <MenuItem value={`allMachines`}>All Machines</MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl sx={{ width: '250px', margin: 1 }}>
                                        <InputLabel>Specific item(s)</InputLabel>
                                        <Select
                                            sx={{ height: '56px' }}
                                            value={generateFormValues.itemsToGet}
                                            labelId="items"
                                            id="items"
                                            label="Specific item(s)"
                                            multiple
                                            required
                                            name="itemsToGet"
                                            MenuProps={MenuProps}
                                            onChange={handleChange}
                                            // renderValue={(selected) => selected.join(', ')}
                                            error={ generateFormValues.itemsToGetErrorValue === true }
                                        >
                                            { generateFormValues.reportFetchResult.map( item => {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        <Checkbox checked={generateFormValues.itemsToGet.indexOf(item) > -1}/>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        <FormHelperText sx={{ textAlign: 'center' }}>Required (Unless "All Machines" is selected)</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant='h6'>Data Tables</Typography>
                        <FormControl component="fieldset" variant="standard" sx={{justifyContent: 'center'}}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{width: 291.08}}
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordHLATable}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordHLATable: !generateFormValues.dailyRecordHLATable}))
                                            }}
                                            name="dailyRecordHLATable" 
                                        />
                                    }
                                    label="Daily Record HLA Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            checked={generateFormValues.dailyRecordsToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordsToggle: !generateFormValues.dailyRecordsToggle}))
                                            }}
                                            name="dailyRecordsToggle" 
                                        />
                                    }
                                    label="Daily Records Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.errorTableToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, errorTableToggle: !generateFormValues.errorTableToggle}))
                                            }}
                                            name="errorTableToggle" 
                                        />
                                    }
                                    label="Error Table"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.programTableToggle}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, programTableToggle: !generateFormValues.programTableToggle}))
                                            }}
                                            name="programTableToggle" 
                                        />
                                    }
                                    label="Program Table"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant='h6'>Data Graphs</Typography>
                        <FormControl component="fieldset" variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.AverageOperatingTimePieChart}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, AverageOperatingTimePieChart: !generateFormValues.AverageOperatingTimePieChart}))
                                            }}
                                            name="AverageOperatingTimePieChart" 
                                        />
                                    }
                                    label="Average Operating Time Pie Chart"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordsBarGraph}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordsBarGraph: !generateFormValues.dailyRecordsBarGraph}))
                                            }}
                                            name="dailyRecordBarGraph" 
                                        />
                                    }
                                    label="Daily Records Bar Graph"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.dailyRecordHLABarGraph}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, dailyRecordHLABarGraph: !generateFormValues.dailyRecordHLABarGraph}))
                                            }}
                                            name="dailyRecordHLABarGraph" 
                                        />
                                    }
                                    label="Daily Record HLA Bar Graph"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={generateFormValues.groupOverview}
                                            onChange={(e) => {
                                                if (parsedData !== parsedDataInitState) {
                                                    setReport(reportValuesInitState)
                                                }
                                                return setGenerateFormValues(prev => ({...prev, groupOverview: !generateFormValues.groupOverview}))
                                            }}
                                            name="Overview"
                                        />
                                    }
                                    label="Overview"
                                />
                            </FormGroup>
                            <Typography gutterBottom variant='h6'>Filters</Typography>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={generateFormValues.excludeWeekend}
                                                onChange={(e) => {
                                                    if (parsedData !== parsedDataInitState) {
                                                        setReport(reportValuesInitState)
                                                    }
                                                    return setGenerateFormValues(prev => ({...prev, excludeWeekend: !generateFormValues.excludeWeekend}))
                                                }}
                                                name="excludeWeekend" 
                                            />
                                        }
                                        label="Exclude Weekends"
                                    />
                                </FormGroup>
                            </FormControl>
                        </FormControl>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                    <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }}>
                        <Grid container 
                            direction="column" 
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography gutterBottom textAlign='center' variant='h6' sx={{ paddingBottom: 1 }}>Date Options</Typography>
                                <DatePicker 
                                    label="Start Date" 
                                    timezone="America/Chicago"
                                    value={generateFormValues.startDate}
                                    onChange={(e) => {
                                        if (parsedData !== parsedDataInitState) {
                                            setReport(reportValuesInitState)
                                        }
                                        return setGenerateFormValues(prev => ({...prev, startDate: e}))
                                    }}
                                    maxDate={dayjs()}
                                />
                                <FormHelperText sx={{ paddingLeft: 2, paddingBottom: 3 }}>Start date is date selected at 12:00AM</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker 
                                    label="End Date" 
                                    timezone="America/Chicago"
                                    value={generateFormValues.endDate}
                                    onChange={(e) => {
                                        if (parsedData !== parsedDataInitState) {
                                            setReport(reportValuesInitState)
                                        }
                                        return setGenerateFormValues(prev => ({...prev, endDate: e}))
                                    }}
                                    maxDate={dayjs()}
                                />
                                <FormHelperText sx={{ paddingLeft: 2, paddingBottom: 2 }}>End date is date selected at 11:59PM</FormHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                    <Grid item sx={{ paddingBottom: 1, paddingTop: 1 }}>
                        <Button
                            variant="contained"
                            id="resetButton"
                            size='large'
                            sx={{
                                backgroundColor: '#5d6c7c',
                                width: 120,
                                color: 'white',
                                textTransform: 'none',
                                "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                            }}
                            onClick={() => {
                                handleReset()
                                const button = document.querySelector('#resetButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 2000)
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item sx={{ margin: 1 }}>
                    <Button
                            variant="contained" 
                            id="submitButton"
                            size='large'
                            sx={{
                                backgroundColor: '#0e4780',
                                width: 120,
                                color: 'white',
                                textTransform: 'none',
                                "&:hover": { backgroundColor: '#0B3866', color: "white" }
                            }}
                            onClick={() => {
                                handleSubmit()
                                const button = document.querySelector('#submitButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 2000)
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <MachineReportDialog 
                initialState={initialState}
                generateFormValues={generateFormValues}
                setGenerateFormValues={setGenerateFormValues}
                report={report}
            />
        </Box>
    )
}

export default ReportGeneration
