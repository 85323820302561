import React, { useState, useEffect, useRef } from 'react'
import { Grid, Box, List, Typography, Divider, IconButton, Paper, Avatar, Button } from '@mui/material'
import { ListItem, ListItemText, ListItemAvatar, FormControl, InputLabel, Select, MenuItem, Checkbox, FormHelperText } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import GetRoute from '../../GetRoute'
import axios from 'axios'
import { animated, useTrail } from "react-spring"
import MachineCard from '../MachineCard'

const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxHeight: 48 * 7 + 8,
            width: 100,
        },
    },
}

const AdaptiveDashboard = ({ client }) => {

    const myIntervalRef = useRef(null)
    const [availableMachines, setAvailableMachines] = useState([])
    const [machinesToGet, setMachinesToGet] = useState([])
    const [machineData, setMachineData] = useState([])
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const fetchMachines = async () => {
        await client.updateToken(5)
        const response = await axios.post(`${protocol}//${routeIP}:8000/utils/fetchMachines`, {
            token: client.token
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-store'
            }
        })
        setAvailableMachines(response.data)
    }

    const fetchMachineData = async (machinesToGet) => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:5000/api/adaptiveMachineDashboard`, {
                token: client.token,
                machines: machinesToGet
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store',
                }
            })
            setMachineData(response.data)
        } catch (error) {
            console.error("Error fetching machine data:", error) // Logs error if request fails
        }
    }

    useEffect(() => {
        fetchMachines()
        return () => {
            if (myIntervalRef.current) clearInterval(myIntervalRef.current)
        }
        // eslint-disable-next-line
    }, [])

    const fadeTrail = useTrail(machineData.length, {
        config: { mass: .5, tension: 2000, friction: 100 },
        from: { opacity: 0, transform: 'translate3d(0, 5px, 0)' },
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
    })

    const machineCards = machineData.map((machine, index) => (
        <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={machine.name}>
            <animated.div style={fadeTrail[index]}>
                <MachineCard
                    name={machine.name}
                    execution={
                        machine.execution["#text"] === "INTERRUPTED" ||
                        machine.execution["#text"] === "READY" || 
                        machine.execution["#text"] === "PROGRAM_STOPPED"
                        ? "IDLE"
                        : machine.execution["#text"] === "STOPPED"
                        ? "ALARM" 
                        : machine.execution["#text"]
                    }
                    program={machine.program["#text"]}
                    pathFeedrateOverride={
                        machine.pathFeedrateOverride["#text"] === "" ||
                        machine.pathFeedrateOverride["#text"] === "UNAVAILABLE"
                        ? "0"
                        : machine.pathFeedrateOverride["#text"]
                    }
                    pathFeedrate={machine.pathFeedrate["#text"]}
                    operatingTime={machine.accumulatedTime[1]["#text"]}
                    spindleRunTime={machine.accumulatedTime[7]["#text"]}
                    spindleRunTimePerMonth={machine.spindleRunTimePerMonth}
                    pathPosition={machine.pathPosition["#text"]}
                    spindleStartTimePerProgram={machine.spindleStartTimePerProgram["#text"]}
                    client={client}
                />
            </animated.div>
        </Grid>
    ))

    const handleChange = (event) => {
        const value = event.target.value
        setMachinesToGet(value.sort())
    }

    const handleClear = () => {
        if (myIntervalRef.current) clearInterval(myIntervalRef.current)
        setMachinesToGet([])
        setMachineData([])
    }

    return (
            <Grid container sx={{
                    margin: 1,
                    minHeight: 'calc(100vh - 75px)',
                    maxWidth: '1920px',
                    display: 'flex',
                    marginInline: 'auto'
                }}
            >
                <Grid item xs={12} sm={12} md={4} lg={3} xl={2} sx={{ maxHeight: '100%' }}>
                    <Grid container sx={{ flexDirection: 'column', height: '100%' }}>
                        <Paper elevation={4} sx={{ height: '100%' }}>
                            <Grid item sx={{ height: '20%' }}>
                                <Box sx={{ padding: 1 }}>
                                    <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                        <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color: 'white' }}>Machine Selection</Typography>
                                    </Paper>
                                </Box>
                                <FormControl sx={{ width: { xs: '370px', sm: '571px', md: '290px', lg: '280px', xl: '250px' }, margin: 1, alignSelf: 'center' }}>
                                    <InputLabel>Machines</InputLabel>
                                    <Select
                                        sx={{ height: '56px' }}
                                        value={machinesToGet}
                                        label="Machines"
                                        multiple
                                        onChange={handleChange}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) =>  {
                                            if (selected.length === 1) return `${selected.length} Machine selected`
                                            return `${selected.length} Machines selected`
                                        }}
                                    >
                                        {availableMachines.map(machine => (
                                            <MenuItem key={machine} value={machine} disabled={machinesToGet.length >= 12 && !machinesToGet.includes(machine) ? true : false}>
                                                <Checkbox checked={machinesToGet.indexOf(machine) > -1}/>
                                                {machine}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>Select up to 12 machines</FormHelperText>
                                </FormControl>
                                <Divider />
                            </Grid>
                            <Grid item sx={{ height: '70%' }}>
                                <Box sx={{ padding: 1 }}>
                                    <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                        <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color: 'white' }}>Selected Machines</Typography>
                                    </Paper>
                                </Box>
                                <List dense sx={{ paddingTop: 0, flexGrow: 1, maxHeight: '100%', overflowY: 'auto' }}>
                                    {machinesToGet.map(machine => (
                                        <ListItem
                                        sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: .5 }}
                                            key={machine}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                    const updatedMachinesToGet = machinesToGet.filter((word) => word !== machine)
                                                    setMachinesToGet(updatedMachinesToGet)
                                                    clearInterval(myIntervalRef.current)
                                                    setMachineData([])
                                                    fetchMachineData(updatedMachinesToGet)
                                                    myIntervalRef.current = setInterval(async () => {await fetchMachineData(updatedMachinesToGet)}, 3000)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar sx={machineData.some((item) => item.name === machine) ? {backgroundColor:'#8BAA7D'} : {backgroundColor:'#D67073'}}>
                                                    <PrecisionManufacturingIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={machine} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Divider />
                            </Grid>
                            <Grid item sx={{ height: '10%', alignContent: 'end' }}>
                                <Grid container justifyContent="center">
                                    <Grid item sx={{ width: '50%', padding: 1 }}>
                                        <Button
                                            variant="contained"
                                            id="clearButton"
                                            size='large'
                                            sx={{
                                                backgroundColor: '#5d6c7c',
                                                color: 'white',
                                                width: '100%',
                                                textTransform: 'none',
                                                "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                            }}
                                            onClick={() => {
                                                const button = document.querySelector('#clearButton')
                                                button.disabled = true
                                                handleClear()
                                                setTimeout(() => button.disabled = false, 2000)
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ width: '50%', padding: 1, paddingLeft: 0, alignContent: 'end' }}>
                                        <Button
                                            variant="contained"
                                            id="submitButton"
                                            size='large'
                                            sx={{
                                                backgroundColor: '#0e4780',
                                                color: 'white',
                                                width: '100%',
                                                textTransform: 'none',
                                                "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                            }}
                                            onClick={() => {
                                                const button = document.querySelector('#submitButton')
                                                button.disabled = true
                                                if (myIntervalRef.current) {
                                                    clearInterval(myIntervalRef.current)
                                                    setMachineData([])
                                                }
                                                fetchMachineData(machinesToGet)
                                                myIntervalRef.current = setInterval(async () => {await fetchMachineData(machinesToGet)}, 3000)
                                                setTimeout(() => button.disabled = false, 2000)
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                    <Paper elevation={4} sx={{ height: '100%', marginLeft: {xs: 0, sm: 0, md: 1}, backgroundColor: 'lightgrey', overflow: 'auto'}}>
                        <Grid container 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: "baseline",
                                marginInline: 'auto',
                                maxWidth: '1920px'
                            }}
                        >
                            <Grid item xs={12} sx={{ width: '100%', height: 'fit-content', margin: 0 }}>
                                <Box sx={{ padding: 1, paddingBottom: 0 }}>
                                    <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                        <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color: 'white', fontSize: 30, margin: 0 }}>Adaptive Dashboard</Typography>
                                    </Paper>
                                </Box>
                            </Grid>
                            { machineCards }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
    )
}    


export default AdaptiveDashboard