import React, { useState, useEffect } from 'react'
import { Grid, Typography, IconButton } from '@mui/material/'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import MachineCard from '../MachineCard'
import { animated, useTrail } from "react-spring"
import GetRoute from '../../GetRoute'
import axios from 'axios'

const headerMap = new Map([["Machine", "allMachines"],["Building", "building"]])

const GroupedDashboards = ({ client }) => {
    const [data, setData] = useState([])
    const [startIndex, setStartIndex] = useState(0)
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    const routePath = window.location.pathname
    const scopeToRequest = routePath.slice(1)
    const totalCards = data.length
    const cardsPerSet = 12

    //This code needs to go into a seprate function
    const PageHeader = routePath.replace(/-/g, " ").slice(1)
    const dashboardType = PageHeader.split(" ")
    const dashboardTypeToFetch = (headerMap.get(dashboardType[0]) || "department")
    
    if ((startIndex > data.length || startIndex === data.length) && data.length !== 0) {
        setStartIndex(0)
    }

    const fetchData = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:5000/api/getDashboard`, {
                dashboardType: dashboardTypeToFetch,
                scope: scopeToRequest,
                token: client.token
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store, must-revalidate'
                }
            })

            setData(response.data)
        } catch (error) {
            console.log(error.message)
        }
    }

    const fadeTrail = useTrail(cardsPerSet, {
        config: { mass: 1, tension: 700, friction: 75 },
        from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
    })

    const nextPage = () => {
        setStartIndex((prevIndex) => {
            if (prevIndex + cardsPerSet <= totalCards) {
                return (prevIndex + cardsPerSet) % totalCards
            }
                return prevIndex 
        })
    }

    const prevPage = () => {
        setStartIndex((prevIndex) => {
            const newIndex = prevIndex - cardsPerSet
            if (prevIndex !== 0) {
                return newIndex < 0 ? totalCards - cardsPerSet : newIndex
            }
            return prevIndex
        })
    }

    const startCardInterval = (totalCards) => {
        const cardIntervalId = setInterval(() => {
            setStartIndex((prevIndex) => {
                const newIndex = prevIndex + cardsPerSet
                if (newIndex >= totalCards) {
                    return newIndex % totalCards
                } else {
                    return newIndex
                }
            })
        }, 15000)

        return cardIntervalId
    }

    useEffect((totalCards) => {
        fetchData() // Fetch data on initial render
        const cardIntervalId = startCardInterval(totalCards)
        const dataIntervalId = setInterval(() => {
          fetchData() // Fetch data every 4 seconds
        }, 3000)
        return () => {
            clearInterval(dataIntervalId) // Clear data interval on component unmount
            clearInterval(cardIntervalId)
        }
        // eslint-disable-next-line
    }, [routePath])

    const totalSets = Math.ceil(data.length / cardsPerSet)
    const endIndex = Math.min(startIndex + cardsPerSet, data.length)
    const currentData = data.slice(startIndex, endIndex)
    const firstMachinesInSet = determineFirstMachineInSets(data)
    var pageNum = 0

    const machineCards = fadeTrail.map((style, index) => {
        if (index < currentData.length) {
            const machine = currentData[index]

            //This logic checks the first machine in the set to determine page
            if(firstMachinesInSet.includes(machine)) {
                for(var i = 0; i < firstMachinesInSet.length; i++){
                    if (machine === firstMachinesInSet[i]) {
                        pageNum = i + 1
                    }
                }
            }

            return (
                <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={machine.name}>
                    <animated.div style={style}>
                        <MachineCard
                            name={machine.name}
                            execution={
                                machine.execution["#text"] === "INTERRUPTED" ||
                                machine.execution["#text"] === "READY" || 
                                machine.execution["#text"] === "PROGRAM_STOPPED"
                                ? "IDLE": machine.execution["#text"] === "STOPPED"
                                ? "ALARM" : machine.execution["#text"]
                            }
                            program={machine.program["#text"]}
                            pathFeedrateOverride={
                                machine.pathFeedrateOverride["#text"] === "" ||
                                machine.pathFeedrateOverride["#text"] === "UNAVAILABLE"
                                ? "0"
                                : machine.pathFeedrateOverride["#text"]
                            }
                            pathFeedrate={machine.pathFeedrate["#text"]}
                            operatingTime={machine.accumulatedTime[1]["#text"]}
                            spindleRunTime={machine.accumulatedTime[7]["#text"]}
                            spindleRunTimePerMonth={machine.spindleRunTimePerMonth}
                            pathPosition={machine.pathPosition["#text"]}
                            spindleStartTimePerProgram={machine.spindleStartTimePerProgram["#text"]}
                            client={client}
                        />
                    </animated.div>
                </Grid>
            )
        } else {
            return null
        }
    })

    return (
        <Grid container 
            sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: "baseline",
                marginInline: 'auto',
                maxWidth: '1920px',
                minHeight: 'calc(100vh - 63.59px)'
            }}
        >
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={prevPage} size="large">
                    <ArrowLeftIcon fontSize="large"/>
                </IconButton>
                <Typography variant="h3" sx={{ paddingTop: 1, paddingBottom: 2 }}>
                    {PageHeader} {pageNum}/{totalSets}
                </Typography>
                <IconButton onClick={nextPage} size="large">
                    <ArrowRightIcon fontSize="large"/>
                </IconButton>
            </Grid>
            { machineCards }
        </Grid>
    )
}

//The first machine in each set will determine what page is being shown
function determineFirstMachineInSets(data) {
    var firstMachineList = []
    for(var i = 0; i < data.length; i++) {
        if (i % 12 === 0) {
            firstMachineList.push(data[i])
        }
    }

    return firstMachineList
}

export default GroupedDashboards
