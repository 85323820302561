import * as React from 'react'
import {Box, Stepper, Step, StepLabel, StepContent, Typography, StepConnector } from '@mui/material/'
import { styled } from '@mui/system'

    const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
        '& .MuiStepConnector-line': {
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderColor: '#1976d2', // Customize the line color
            minHeight: '0px', // Adjust the line height
        },
    }))

    export default function VerticalStepper({ activeStep, purchaseOrderState }) {
        const steps = [
            {
                label: 'Input Purchase Contract',
                purchaseOrder: '',
                description: `Drop a Boeing Purchase Contract PDF into the Drop Zone for the Importer to analyze.`,
            },
            {
                label: 'Verify Information',
                purchaseOrder: `${purchaseOrderState.fileName}`,
                description:`Check the data that was gathered by the Importer. Confirm the data is correct, fix errors where necessary.`,
            },
            {
                label: 'Check Contact Information',
                purchaseOrder: `${purchaseOrderState.fileName}`,
                description: `Review the contact information when necessary and update/submit the information to the database if needed.`,
            },
            {
                label: 'Check Sales Order',
                purchaseOrder: `${purchaseOrderState.fileName}`,
                description: `Review the sales order information when necessary and update/submit the sales order to the database if needed.`,
            }
        ]
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 1 }}>
            <Stepper
                activeStep={activeStep}
                orientation="vertical"
                connector={<CustomStepConnector />}
                sx={{ height: '100%' }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label} sx={{ height: '100%' }}>
                        <StepLabel
                            optional={
                                index === steps.length - 1 ? (
                                <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                        >
                        {step.label}
                        </StepLabel>
                        <StepContent sx={{ height: '85%' }}>
                            {step.purchaseOrder !== '' ? (
                                <Typography variant='h6' sx={{ fontWeight: 'medium'}}>PC: {step.purchaseOrder}</Typography>
                            ) : (<></>)}
                            <Typography>{step.description}</Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}
