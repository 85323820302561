import * as React from 'react'
import { Grid, Fade, Box, Typography, Button } from '@mui/material/'

export default function MultipleContactsFound({ buyerInfo, previousStep, nextStep }) {

    return (
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', padding: 1, height: '400px' }}>
            <Fade
                in={true}
                unmountOnExit
            >
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h6'>
                        Multiple customer contacts where found with the name: <span className="purchaseContactImportance">{buyerInfo.buyer_name}</span>.
                    </Typography>
                    <Typography variant='h6'>
                        If you wish to update or manage these contacts, please do so in Jobboss.
                    </Typography>
                    <Typography variant='h6'>
                        Otherwise, you may proceed to The Sales Order Check.
                    </Typography>
                    <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'end', paddingTop: 3 }}>
                        <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                            <Button
                                variant="contained"
                                id="submitButton"
                                size='large'
                                sx={{ 
                                    backgroundColor: '#5d6c7c',
                                    width: '100%',
                                    minWidth: '123px',
                                    color: 'white',
                                    textTransform: 'none',
                                    "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                }}
                                onClick={() => {
                                    const button = document.querySelector('#submitButton')
                                    button.disabled = true
                                    previousStep()
                                    setTimeout(() => button.disabled = false, 2000)
                                }}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyItems: 'end' }}>
                            <Button
                                variant="contained"
                                id="submitButton"
                                size='large'
                                // disabled={purchaseOrderState.pdf == null}
                                sx={{
                                    backgroundColor: '#0e4780',
                                    color: 'white',
                                    width: '100%',
                                    minWidth: '123px',
                                    textTransform: 'none',
                                    "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                }}
                                onClick={() => {
                                    const button = document.querySelector('#submitButton')
                                    button.disabled = true
                                    nextStep()
                                    setTimeout(() => button.disabled = false, 2000)
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Grid>
    )
}